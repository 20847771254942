import * as React from "react";
import SvgIcon from "@mui/material/SvgIcon";

export default function ProjectsIcon(props) {
  return (

    <SvgIcon sx={props.sx}>
      {props.mobile ? 
        (
          <svg
          width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g opacity="0.87">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M15.75 5.77117V6V8H18.1342L15.75 5.77117ZM21.7107 8.63379C22.0452 8.99131 22.25 9.47174 22.25 10V20C22.25 21.1046 21.3546 22 20.25 22H6.75C5.64543 22 4.75 21.1046 4.75 20V18.1973C2.9566 17.1599 1.75 15.2208 1.75 13C1.75 10.7792 2.9566 8.84012 4.75 7.8027V4C4.75 2.89543 5.64543 2 6.75 2H13.75C13.8498 2 13.9479 2.00731 14.0437 2.02142C14.3518 1.95677 14.6855 2.0382 14.9329 2.26949L21.5764 8.48002C21.6272 8.52754 21.672 8.57912 21.7107 8.63379ZM6.75 20V18.917C7.07521 18.9716 7.40929 19 7.75 19C11.0637 19 13.75 16.3137 13.75 13C13.75 9.68629 11.0637 7 7.75 7C7.40929 7 7.07521 7.0284 6.75 7.08296V4H13.75L13.75 6V8C13.75 9.10457 14.6455 10 15.75 10H20.25V20H6.75ZM7.75 17C9.95914 17 11.75 15.2091 11.75 13C11.75 10.7909 9.95914 9 7.75 9C5.54086 9 3.75 10.7909 3.75 13C3.75 15.2091 5.54086 17 7.75 17ZM9.75 11C9.75 10.4477 9.30228 10 8.75 10C8.19772 10 7.75 10.4477 7.75 11V13H6.75C6.19772 13 5.75 13.4477 5.75 14C5.75 14.5523 6.19772 15 6.75 15H8.75C9.30229 15 9.75 14.5523 9.75 14V11Z"
              fill="#323232"
            />
            </g>
          </svg>
        )
      :
        (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M15.75 5.77117V6V8H18.1342L15.75 5.77117ZM21.7107 8.63379C22.0452 8.99131 22.25 9.47174 22.25 10V20C22.25 21.1046 21.3546 22 20.25 22H6.75C5.64543 22 4.75 21.1046 4.75 20V18.1973C2.9566 17.1599 1.75 15.2208 1.75 13C1.75 10.7792 2.9566 8.84012 4.75 7.8027V4C4.75 2.89543 5.64543 2 6.75 2H13.75C13.8498 2 13.9479 2.00731 14.0437 2.02142C14.3518 1.95677 14.6855 2.0382 14.9329 2.26949L21.5764 8.48002C21.6272 8.52754 21.672 8.57912 21.7107 8.63379ZM6.75 20V18.917C7.07521 18.9716 7.40929 19 7.75 19C11.0637 19 13.75 16.3137 13.75 13C13.75 9.68629 11.0637 7 7.75 7C7.40929 7 7.07521 7.0284 6.75 7.08296V4H13.75L13.75 6V8C13.75 9.10457 14.6455 10 15.75 10H20.25V20H6.75ZM7.75 17C9.95914 17 11.75 15.2091 11.75 13C11.75 10.7909 9.95914 9 7.75 9C5.54086 9 3.75 10.7909 3.75 13C3.75 15.2091 5.54086 17 7.75 17ZM9.75 11C9.75 10.4477 9.30228 10 8.75 10C8.19772 10 7.75 10.4477 7.75 11V13H6.75C6.19772 13 5.75 13.4477 5.75 14C5.75 14.5523 6.19772 15 6.75 15H8.75C9.30229 15 9.75 14.5523 9.75 14V11Z"
              fill="#F4F4F4"
            />
          </svg>
        )}
       
    </SvgIcon>
  );
}
