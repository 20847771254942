import * as React from "react";
import SvgIcon from "@mui/material/SvgIcon";

export default function StockIssuesIcon(props) {
  return (
    <SvgIcon sx={props.sx}>
      {
        props.mobile ?
          (
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g opacity="0.87">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M5.00447 16L7.27139 12.7829C7.84702 11.9659 8.05205 11.03 8.03438 10.1895C8.01563 9.29765 8.11671 7.89074 8.68782 6.77716C8.95711 6.2521 9.30972 5.83349 9.77623 5.53834C10.237 5.24683 10.928 5 12 5C13.072 5 13.7629 5.24683 14.2237 5.53834C14.6902 5.83349 15.0428 6.2521 15.3121 6.77716C15.8832 7.89074 15.9843 9.29765 15.9656 10.1895C15.9479 11.03 16.1529 11.9659 16.7286 12.7829L18.9955 16L17.001 16H15.001H9.00098H7.00098H5.00447ZM7.41702 18H5.00447C3.38359 18 2.43594 16.173 3.36958 14.848L5.6365 11.6308C5.92256 11.2249 6.04526 10.728 6.03482 10.2315C5.99271 8.2281 6.4176 3 12 3C17.5823 3 18.0072 8.2281 17.9651 10.2315C17.9547 10.728 18.0774 11.2249 18.3634 11.6308L20.6304 14.848C21.564 16.173 20.6164 18 18.9955 18H16.5849C15.8134 19.7659 14.0513 21 12.001 21C9.95066 21 8.18858 19.7659 7.41702 18ZM9.76487 18C10.3142 18.6137 11.1125 19 12.001 19C12.8895 19 13.6878 18.6137 14.2371 18H9.76487Z"
                  fill="#323232"
                />
              </g>
            </svg>
          )
        :
          (<svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M5.00447 16L7.27139 12.7829C7.84702 11.9659 8.05205 11.03 8.03438 10.1895C8.01563 9.29765 8.11671 7.89074 8.68782 6.77716C8.95711 6.2521 9.30972 5.83349 9.77623 5.53834C10.237 5.24683 10.928 5 12 5C13.072 5 13.7629 5.24683 14.2237 5.53834C14.6902 5.83349 15.0428 6.2521 15.3121 6.77716C15.8832 7.89074 15.9843 9.29765 15.9656 10.1895C15.9479 11.03 16.1529 11.9659 16.7286 12.7829L18.9955 16L17.001 16H15.001H9.00098H7.00098H5.00447ZM7.41702 18H5.00447C3.38359 18 2.43594 16.173 3.36958 14.848L5.6365 11.6308C5.92256 11.2249 6.04526 10.728 6.03482 10.2315C5.99271 8.2281 6.4176 3 12 3C17.5823 3 18.0072 8.2281 17.9651 10.2315C17.9547 10.728 18.0774 11.2249 18.3634 11.6308L20.6304 14.848C21.564 16.173 20.6164 18 18.9955 18H16.5849C15.8134 19.7659 14.0513 21 12.001 21C9.95066 21 8.18858 19.7659 7.41702 18ZM9.76487 18C10.3142 18.6137 11.1125 19 12.001 19C12.8895 19 13.6878 18.6137 14.2371 18H9.76487Z"
              fill="#F4F4F4"
            />
          </svg>)
      }
      


    </SvgIcon>
  );
}
