import * as React from "react";
import SvgIcon from "@mui/material/SvgIcon";

export default function RoomsIcon(props) {
  return (
    <SvgIcon sx={props.sx}>
      {
        props.mobile ?
          (
            <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g opacity="0.87">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17 9.35947C17 10.9155 16.1349 12.9306 14.818 14.9923C13.8833 16.4556 12.8282 17.7796 12 18.7438C11.1718 17.7796 10.1167 16.4556 9.18202 14.9923C7.8651 12.9306 7 10.9155 7 9.35947C7 6.88678 9.14067 4.70947 12 4.70947C14.8593 4.70947 17 6.88678 17 9.35947ZM19 9.35947C19 13.6739 14.4475 19.0615 12.6852 20.9855C12.3122 21.3927 11.6878 21.3927 11.3148 20.9855C9.55246 19.0615 5 13.6739 5 9.35947C5 5.68678 8.13401 2.70947 12 2.70947C15.866 2.70947 19 5.68678 19 9.35947ZM13 9.70947C13 10.2618 12.5523 10.7095 12 10.7095C11.4477 10.7095 11 10.2618 11 9.70947C11 9.15719 11.4477 8.70947 12 8.70947C12.5523 8.70947 13 9.15719 13 9.70947ZM15 9.70947C15 11.3663 13.6569 12.7095 12 12.7095C10.3431 12.7095 9 11.3663 9 9.70947C9 8.05262 10.3431 6.70947 12 6.70947C13.6569 6.70947 15 8.05262 15 9.70947Z"
                fill="#323232"
              />
            </g>
          </svg>
          )
        :
          (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M17 9.35947C17 10.9155 16.1349 12.9306 14.818 14.9923C13.8833 16.4556 12.8282 17.7796 12 18.7438C11.1718 17.7796 10.1167 16.4556 9.18202 14.9923C7.8651 12.9306 7 10.9155 7 9.35947C7 6.88678 9.14067 4.70947 12 4.70947C14.8593 4.70947 17 6.88678 17 9.35947ZM19 9.35947C19 13.6739 14.4475 19.0615 12.6852 20.9855C12.3122 21.3927 11.6878 21.3927 11.3148 20.9855C9.55246 19.0615 5 13.6739 5 9.35947C5 5.68678 8.13401 2.70947 12 2.70947C15.866 2.70947 19 5.68678 19 9.35947ZM13 9.70947C13 10.2618 12.5523 10.7095 12 10.7095C11.4477 10.7095 11 10.2618 11 9.70947C11 9.15719 11.4477 8.70947 12 8.70947C12.5523 8.70947 13 9.15719 13 9.70947ZM15 9.70947C15 11.3663 13.6569 12.7095 12 12.7095C10.3431 12.7095 9 11.3663 9 9.70947C9 8.05262 10.3431 6.70947 12 6.70947C13.6569 6.70947 15 8.05262 15 9.70947Z"
              fill="#F4F4F4"
            />
          </svg>
          )
      }
      
    </SvgIcon>
  );
}
