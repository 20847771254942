import * as React from "react";
import SvgIcon from "@mui/material/SvgIcon";

export default function ToolIcon(props) {
  return (
    <SvgIcon sx={props.sx}>
      {props.mobile ?
      (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g opacity="0.87">
            <path d="M6.98228 9.98228H9.98228V6.98228L6.48228 3.48228C7.60191 2.94755 8.85978 2.77308 10.0827 2.9829C11.3056 3.19271 12.4333 3.77649 13.3107 4.65385C14.1881 5.5312 14.7718 6.65898 14.9817 7.88188C15.1915 9.10478 15.017 10.3626 14.4823 11.4823L20.4823 17.4823C20.8801 17.8801 21.1036 18.4197 21.1036 18.9823C21.1036 19.5449 20.8801 20.0845 20.4823 20.4823C20.0845 20.8801 19.5449 21.1036 18.9823 21.1036C18.4197 21.1036 17.8801 20.8801 17.4823 20.4823L11.4823 14.4823C10.3626 15.017 9.10478 15.1915 7.88188 14.9817C6.65898 14.7718 5.5312 14.1881 4.65385 13.3107C3.77649 12.4333 3.19271 11.3056 2.9829 10.0827C2.77308 8.85978 2.94755 7.60191 3.48228 6.48228L6.98228 9.98228Z"
            stroke="#323232" 
            strokeWidth="2" 
            strokeLinecap="round" 
            strokeLinejoin="round"/>
          </g>
        </svg>
      )
    :
      (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6.98228 9.98228H9.98228V6.98228L6.48228 3.48228C7.60191 2.94755 8.85978 2.77308 10.0827 2.9829C11.3056 3.19271 12.4333 3.77649 13.3107 4.65385C14.1881 5.5312 14.7718 6.65898 14.9817 7.88188C15.1915 9.10478 15.017 10.3626 14.4823 11.4823L20.4823 17.4823C20.8801 17.8801 21.1036 18.4197 21.1036 18.9823C21.1036 19.5449 20.8801 20.0845 20.4823 20.4823C20.0845 20.8801 19.5449 21.1036 18.9823 21.1036C18.4197 21.1036 17.8801 20.8801 17.4823 20.4823L11.4823 14.4823C10.3626 15.017 9.10478 15.1915 7.88188 14.9817C6.65898 14.7718 5.5312 14.1881 4.65385 13.3107C3.77649 12.4333 3.19271 11.3056 2.9829 10.0827C2.77308 8.85978 2.94755 7.60191 3.48228 6.48228L6.98228 9.98228Z"
            stroke="#F4F4F4"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg> 
      )}
    </SvgIcon>
  );
}
